var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"margin":"0px","padding":"0px","height":"100%","width":"100vw"}},[_c('Menu',{staticStyle:{"position":"fixed","height":"50px","z-index":"1","left":"0","right":"0","background":"#DBC7FF"},attrs:{"title":"Back","distribution":true}}),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"width":"100vw","background":"#F4F9FF","height":"100%","margin":"0px","padding-top":"50px","min-height":"calc(100vh - 120px)"}},[_c('div',{staticStyle:{"overflow":"hidden","min-height":"inherit","display":"flex","height":"100%","width":"100%"}},[_c('distribution-filter',{staticClass:"ml-15"}),_c('div',{staticClass:"table-content",staticStyle:{"width":"100%","height":"100%","overflow":"auto","padding-right":"30px"},attrs:{"color":"#F4F9FF"}},[_c('div',{staticClass:"px-5 pb-5"},[_c('v-card-title',[_c('label',{staticClass:"item_title"},[_vm._v("Distribution History")])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.distributions,"items-per-page":15,"loading":_vm.loading,"search":_vm.search,"custom-filter":_vm.customFilter,"show-expand":"","expanded":_vm.expanded,"item-key":"_id"},on:{"click:row":_vm.onSelectDistribution,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"elevation-6 my-3 ml-5"},[_c('v-img',{attrs:{"src":item.to && item.to.image
                      ? item.to.image
                      : _vm.placeholderImage}})],1)]}},{key:"item.to_name",fn:function(ref){
                      var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.getFiltered(
                    ("" + (item.to && item.to.first_name
                        ? item.to.first_name + ' '
                        : '') + (item.to && item.to.last_name ? item.to.last_name : ''))
                  )
                )}})]}},{key:"item.from_name",fn:function(ref){
                var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.getFiltered(
                    ("" + (item.from && item.from.first_name
                        ? item.from.first_name + ' '
                        : '') + (item.from && item.from.last_name
                        ? item.from.last_name
                        : ''))
                  )
                )}})]}},{key:"item.from_type",fn:function(ref){
                var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.getFiltered(
                    ("" + (item.from && item.from.role ? item.from.role : ''))
                  )
                )}})]}},{key:"item.item_type",fn:function(ref){
                var item = ref.item;
return [_c('div',_vm._l((_vm.categories),function(category){return _c('div',{key:category},[(item[("" + category)])?_c('div',[_vm._v(" "+_vm._s(category)+": "+_vm._s(item[("" + category)])+" ")]):_vm._e()])}),0)]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getFiltered(("" + (item.amount))))}})]}},{key:"item.service",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.from && item.from.type ? item.from.type : "")+" ")]}},{key:"item.createdAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFromIndex(item._id))+" ")]}},{key:"expanded-item",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":_vm.headers.length}},[_c('v-data-table',{staticClass:"px-3",attrs:{"headers":_vm.subHeaders,"items":item.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"100px"},domProps:{"innerHTML":_vm._s(item.good.name)}})]}},{key:"item.serving_size",fn:function(ref){
                var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.good.serving_size + ' ' + item.good.unit)}})]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.good.amount)}})]}},{key:"item.image",fn:function(ref){
                var item = ref.item;
return [(item.good)?_c('div',{staticClass:"ma-3",staticStyle:{"width":"70px","max-width":"70px"}},[_c('v-img',{attrs:{"src":item.good.image ? item.good.image : _vm.placeholderImage,"width":70,"height":50}})],1):_vm._e()]}},{key:"item.nutritions",fn:function(ref){
                var item = ref.item;
return [(item.good.details)?_c('div',{staticStyle:{"width":"120px"}},_vm._l((Object.entries(
                          item.good.details
                        ).map(function (ref) {
                                        var key = ref[0];
                                        var value = ref[1];

                                        return ({ key: key, value: value });
                })),function(detail){return _c('div',{key:detail.key},[_vm._v(" "+_vm._s(detail.key)+" : "+_vm._s(detail.value)+" ")])}),0):_vm._e()]}},{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatTime(item.createdAt))}})]}}],null,true)})],1)]}}],null,true)})],1)])],1)]),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this data? This action can not be undone.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = !_vm.deleteDialog}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.deleteAction}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }