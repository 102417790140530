<template>
  <v-container style="margin: 0px; padding: 0px;height: 100%;width: 100vw;">
    <Menu
      style="position:fixed; height: 50px; z-index: 1; left: 0; right: 0; background: #DBC7FF"
      title="Back"
      :distribution="true"
    />
    <div
      style="width: 100vw; background: #F4F9FF; height: 100%; margin:0px; padding-top:50px; min-height: calc(100vh - 120px)"
      class="d-flex flex-row"
    >
      <div
        style="overflow: hidden; min-height: inherit; display:flex; height: 100%; width: 100%;"
      >
        <distribution-filter class="ml-15" />
        <div
          color="#F4F9FF"
          style="width: 100%; height: 100%; overflow: auto; padding-right: 30px;"
          class="table-content"
        >
          <div class="px-5 pb-5">
            <v-card-title>
              <label class="item_title">Distribution History</label>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="distributions"
              :items-per-page="15"
              :loading="loading"
              @click:row="onSelectDistribution"
              class="elevation-0"
              :search="search"
              :custom-filter="customFilter"
              show-expand
              :expanded.sync="expanded"
              item-key="_id"
            >
              <template v-slot:[`item.image`]="{ item }">
                <v-avatar class="elevation-6 my-3 ml-5">
                  <v-img
                    :src="
                      item.to && item.to.image
                        ? item.to.image
                        : placeholderImage
                    "
                  />
                </v-avatar>
              </template>
              <template v-slot:[`item.to_name`]="{ item }">
                <div
                  v-html="
                    getFiltered(
                      `${
                        item.to && item.to.first_name
                          ? item.to.first_name + ' '
                          : ''
                      }${item.to && item.to.last_name ? item.to.last_name : ''}`
                    )
                  "
                />
              </template>
              <template v-slot:[`item.from_name`]="{ item }">
                <div
                  v-html="
                    getFiltered(
                      `${
                        item.from && item.from.first_name
                          ? item.from.first_name + ' '
                          : ''
                      }${
                        item.from && item.from.last_name
                          ? item.from.last_name
                          : ''
                      }`
                    )
                  "
                />
              </template>
              <template v-slot:[`item.from_type`]="{ item }">
                <div
                  v-html="
                    getFiltered(
                      `${item.from && item.from.role ? item.from.role : ''}`
                    )
                  "
                />
              </template>
              <template v-slot:[`item.item_type`]="{ item }">
                <div>
                  <div v-for="category in categories" :key="category">
                    <div v-if="item[`${category}`]">
                      {{ category }}: {{ item[`${category}`] }}
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <div v-html="getFiltered(`${item.amount}`)" />
              </template>
              <template v-slot:[`item.service`]="{ item }">
                {{ item.from && item.from.type ? item.from.type : "" }}
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ getDateFromIndex(item._id) }}
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length" class="px-0">
                  <v-data-table
                    :headers="subHeaders"
                    :items="item.items"
                    hide-default-footer
                    class="px-3"
                  >
                    <template v-slot:[`item.name`]="{ item }">
                      <div v-html="item.good.name" style="max-width: 100px" />
                    </template>
                    <template v-slot:[`item.serving_size`]="{ item }">
                      <div
                        v-html="item.good.serving_size + ' ' + item.good.unit"
                      />
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
                      <div v-html="item.good.amount" />
                    </template>
                    <template v-slot:[`item.image`]="{ item }">
                      <div
                        class="ma-3"
                        style="width: 70px; max-width: 70px;"
                        v-if="item.good"
                      >
                        <v-img
                          :src="
                            item.good.image ? item.good.image : placeholderImage
                          "
                          :width="70"
                          :height="50"
                        />
                      </div>
                    </template>
                    <template v-slot:[`item.nutritions`]="{ item }">
                      <div v-if="item.good.details" style="width: 120px">
                        <div
                          v-for="detail in Object.entries(
                            item.good.details
                          ).map(([key, value]) => ({ key, value }))"
                          :key="detail.key"
                        >
                          {{ detail.key }} : {{ detail.value }}
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.date`]="{ item }">
                      <div v-html="formatTime(item.createdAt)" />
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this data? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = !deleteDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteAction"
            :loading="loading"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Menu from "@/components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import DistributionFilter from "@/components/DistributionFilter.vue";
import dateFormat from "dateformat";
export default {
  components: { Menu, DistributionFilter },
  data() {
    return {
      isSelectingPhoto: false,
      loading: false,
      errorMessage: null,
      snackbar: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Client Name", value: "to_name" },
        { text: "Partner Name", value: "from_name" },
        { text: "Partner Type", value: "from_type" },
        { text: "Item Type", value: "item_type" },
        { text: "Amount", value: "amount" },
        { text: "date", value: "createdAt" },
        { text: "", value: "data-table-expand" },
      ],
      subHeaders: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Serving size", value: "serving_size" },
        { text: "Number of serving", value: "meals" },
        { text: "Nutritions", value: "nutritions" },
        { text: "Amount", value: "amount" },
        { text: "Date", value: "date" },
        // { text: "Actions", value: "actions" },
      ],
      placeholderImage: require("@/assets/default.jpg"),
      profile: null,
      deleteDialog: false,
      search: "",
      expanded: [],
      categories: [],
    };
  },
  computed: {
    ...mapGetters({
      distributions: "distribution/getAllDistributions",
      keyword: "auth/getKeyword",
    }),
  },
  methods: {
    ...mapActions({
      fetchAllDistributions: "distribution/fetchAllDistributions",
      editDistribution: "distribution/editDistribution",
      deleteDistribution: "distribution/deleteDistribution",
      setBalance: "distribution/setBalance",
      getValues: "variable/getPartnerValues",
    }),
    onSelectDistribution(item) {
      console.log(item);
      //   this.$router.push({ name: "partner-details", params: { id: item._id } });
    },
    getTimeAgo(oldTimeStr) {
      console.log(oldTimeStr);
      var date = new Date(oldTimeStr);
      var dateStr = dateFormat(date, "mm/dd h:MM TT");
      return dateStr;

      // const oldTime = Date.parse(oldTimeStr);
      // const time = new Date();
      // const different = (time.getTime() - oldTime) / 1000;
      // const month = (different / 3600 / 24 / 30) | 0;
      // const days = (different / 3600 / 24) % 30 | 0;
      // const hours = (different / 3600) % 24 | 0;
      // const mins = (different / 60) % 60 | 0;
      // if (month == 1) return `1 month ago`;
      // if (month) return `${month} months ago`;
      // if (days == 1) return `1 day ago`;
      // if (days) return `${days} days ago`;
      // if (hours == 1) return `1 hours ago`;
      // if (hours) return `${hours} hours ago`;
      // if (mins == 1) return `1 min ago`;
      // if (mins) return `${mins} min ago`;
      // return `less than 1 min ago`;
    },
    getDateFromIndex(_id) {
      var date = _id.substring(6, 16);
      return date;
    },
    onDelete() {
      this.deleteDialog = true;
    },
    deleteAction() {
      this.loading = true;
      this.deleteDistribution(this.profile)
        .then(() => {
          this.loading = false;
          this.selected--;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    getDateFromSection(index) {
      var date = new Date();
      var end_date = dateFormat(date, "mm/dd/yyyy");
      if (index == 0) {
        date.setDate(date.getDate() - 7);
      } else if (index == 1) {
        date.setDate(date.getDate() - 30);
      } else if (index == 2) {
        date.setDate(date.getDate() - 365);
      } else {
        return {};
      }
      var start_date = dateFormat(date, "mm/dd/yyyy");
      return { start_date, end_date };
    },
    customSort(items, index, isDesc) {
      if (index.length == 0 || index[0] === "full_name") {
        items.sort((a, b) => {
          var order = 0;
          var aStr = `${a["first_name"]} ${a["last_name"]}`;
          var bStr = `${b["first_name"]} ${b["last_name"]}`;

          if (aStr < bStr) order = -1;
          else if (aStr > bStr) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      } else {
        items.sort((a, b) => {
          var order = 0;
          if (a[index] < b[index]) order = -1;
          else if (a[index] > b[index]) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      }

      return items;
    },
    getFiltered(words) {
      if (!words) return "";
      var filterd = this.$options.filters.highlight(words, this.keyword);
      return filterd;
    },
    customFilter(value, search, item) {
      var searchFilter = false;
      if (this.keyword) {
        if (
          (`${item.to.first_name} ${item.to.last_name}` &&
            `${item.to.first_name} ${item.to.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.from &&
            `${item.from.first_name} ${item.from.last_name}` &&
            `${item.from.first_name} ${item.from.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (`${item.amount}` &&
            `${item.amount}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.date &&
            this.getTimeAgo(item.date)
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.type &&
            item.type.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.email &&
            item.email.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.service &&
            item.service.toUpperCase().includes(this.keyword.toUpperCase()))
        ) {
          searchFilter = true;
        }
      } else {
        searchFilter = true;
      }
      return searchFilter;
    },
    convertTZ(date) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: "America/New_York",
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: "America/New_York",
          }
        )
      );
    },
    formatTime(date) {
      return dateFormat(this.convertTZ(date), "hh:MM tt");
    },
  },
  watch: {
    "$route.query": function(newValue) {
      this.loading = true;
      var dateObject = this.getDateFromSection(newValue.section);
      this.fetchAllDistributions({ ...newValue, ...dateObject })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    keyword(newValue) {
      this.search = newValue;
    },
  },
  created() {
    this.loading = true;
    var dateObject = this.getDateFromSection(this.$route.query.section);
    this.fetchAllDistributions({ ...this.$route.query, ...dateObject })
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
    this.getValues({ title: "Categories" })
      .then((values) => {
        this.categories = values.map((value) => value.text);
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style scoped>
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.distribution_title {
  color: #f0a000;
  font-family: "Poppins-SemiBold";
  font-size: 25px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.profile {
  overflow: visible;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.request_description_item {
  color: #482684;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}

.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}
</style>
